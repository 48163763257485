import React from 'react'

const PlateQuoteForm = () => (
  <form
    className="contact-form"
    name="plateQuote-form"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    method="POST"
    action="#"
  >
    <div className="contact-form--field">
      <label className="contact-form--label">
        Name
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="name"
        placeholder="Enter your name"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Email*
      </label>

      <input
        className="contact-form--input"
        type="email"
        name="email"
        placeholder="Enter your email"
        required
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Phone Number
      </label>

      <input
        className="contact-form--input"
        type="tel"
        name="tel"
        placeholder="Phone Number"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Company
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="company"
        placeholder="Enter your company name"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        ASI/Sage/DC/PPAI Number
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="number"
        placeholder="Enter your number"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Imprint Area
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="imprint"
        placeholder="Imprint Area"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Colors Print
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="imprint"
        placeholder="Colors Print"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Print Side
      </label>

      <div className="contact-form--radioGroup">
        <div className="contact-form--radio">
          <input
            id="plateQuoteRadioSideOne"
            type="radio"
            name="side"
            value="1"
          />
          <label htmlFor="plateQuoteRadioSideOne">
            One
          </label>
        </div>

        <div className="contact-form--radio">
          <input
            id="plateQuoteRadioSideTwo"
            type="radio"
            name="side1"
            value="2"
            defaultChecked
          />
          <label htmlFor="plateQuoteRadioSideTwo">
            Two
          </label>
        </div>
      </div>
    </div>

    <div className="contact-form--field contact-form--field__fullWidth">
      <label className="contact-form--label">
        Message
      </label>

      <textarea
        className="contact-form--input"
        name="message"
        placeholder="Enter your message"
      />
    </div>

    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="plateQuote-form" />

    <button
      className="contact-form--button"
      type="submit"
    >
      Send
    </button>
  </form>
)

export default PlateQuoteForm