import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const theme = (theme) => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary: '#453C5B',
    primary25: '#F48E27',
    primary50: '#F48E27',
    primary75: '#F48E27',
    neutral20: '#DFDFDF',
    neutral30: '#DFDFDF'
  },
})

const SampleRequestForm = ({ colors, bags, defaultColor, defaultBag }) => (
  <form
    className="contact-form"
    name="sampleRequest-form"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    method="POST"
    action="#"
  >
    <div className="contact-form--field">
      <label className="contact-form--label">
        Name
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="name"
        placeholder="Enter your name"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Email*
      </label>

      <input
        className="contact-form--input"
        type="email"
        name="email"
        placeholder="Enter your email"
        required
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Phone Number
      </label>

      <input
        className="contact-form--input"
        type="tel"
        name="tel"
        placeholder="Phone Number"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Company
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="company"
        placeholder="Enter your company name"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        ASI/Sage/DC/PPAI Number
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="number"
        placeholder="Enter your number"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Bag Size
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="size"
        placeholder="Width x Height x Gusset"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Bag type
      </label>

      <Select
        className="contact-form--select"
        classNamePrefix="contact-form--select"
        placeholder="Please select type"
        options={ bags }
        theme={ theme }
        isClearable={ true }
        defaultValue={ defaultBag }
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label contact-form--label__small">
        Film Color
      </label>

      <Select
        className="contact-form--select"
        classNamePrefix="contact-form--select"
        placeholder="Please select color"
        options={ colors }
        theme={ theme }
        isClearable={ true}
        defaultValue={ defaultColor }
      />
    </div>

    <div className="contact-form--field contact-form--field__twoThird">
      <label className="contact-form--label">
        Street
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="street"
        placeholder="Street Address"
      />
    </div>

    <div className="contact-form--field contact-form--field__oneThird">
      <label className="contact-form--label contact-form--label__small">
        Unit
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="unit"
        placeholder="Unit"
      />
    </div>

    <div className="contact-form--field contact-form--field__oneThird">
      <label className="contact-form--label contact-form--label__small">
        City
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="city"
        placeholder="City"
      />
    </div>

    <div className="contact-form--field contact-form--field__oneThird">
      <label className="contact-form--label contact-form--label__small">
        State
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="state"
        placeholder="State"
      />
    </div>

    <div className="contact-form--field contact-form--field__oneThird">
      <label className="contact-form--label contact-form--label__small">
        Zip
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="zip"
        placeholder="Zip"
      />
    </div>

    <div className="contact-form--field contact-form--field__fullWidth">
      <label className="contact-form--label">
        Message
      </label>

      <textarea
        className="contact-form--input"
        name="message"
        placeholder="Enter your message"
      />
    </div>

    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="sampleRequest-form" />

    <button
      className="contact-form--button"
      type="submit"
    >
      Send
    </button>
  </form>
)

SampleRequestForm.propTypes = {
  colors: PropTypes.array.isRequired,
  bags: PropTypes.array.isRequired,
  defaultColor: PropTypes.object,
  defaultBag: PropTypes.object
}

export default SampleRequestForm