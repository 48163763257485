import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '../components/link'
import BagSlider from '../components/bagSlider'
import BagColors from '../components/bagColors'
import Popup from '../components/popup'
import SampleRequestForm from '../components/sampleRequestForm'
import QuoteForm from '../components/quoteForm'
import PlateQuoteForm from '../components/plateQuoteForm'
import PriceTable from '../components/priceTable'

class BagTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeColor: null,
      popup: null
    }

    this.onColorSelect = this.onColorSelect.bind(this)
    this.showPopup = this.showPopup.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }

  onColorSelect(colorName) {
    const color = this.state.activeColor === colorName ? null : colorName;
    this.setState({
      ...this.state,
      activeColor: color
    })
  }

  showPopup(popupName) {
    console.log(1);
    this.setState({
      ...this.state,
      popup: popupName
    }, () => document.body.style.overflow = 'hidden')
  }

  onClosePopup(e) {
    if (e.target !== e.currentTarget) {
      return
    }

    this.setState({
      ...this.state,
      popup: null
    }, () => document.body.style.overflow = '')
  }

  render() {
    const {
      data: {
        Bag: {
          name,
          image,
          leadtime,
          thickness,
          description
        },
        GalleryImages,
        GalleryThumbnails,
        Pricelist,
        Bags,
        Colors
      }
    } = this.props;

    const { activeColor, popup } = this.state;

    const colorsList = Colors.nodes.map(node => {
      return {
        value: node.name,
        label: node.name
      }
    });
    const bagsList = Bags.nodes.map(node => {
      return {
        value: node.name,
        label: node.name
      }
    })
    const defaultColor = colorsList.find(color => color.value === activeColor);
    const defaultBag = bagsList.find(bag => bag.value === name);

    return (
      <Layout>
        <SEO title={ name } />

        <div className="bag-breadcrumbs">
          <Link
            className="bag-breadcrumbs--link"
            to="/bags"
          >
            Plastic Bags
          </Link>
          <span className="bag-breadcrumbs--delimiter">></span>
          <span className="bag-breadcrumbs--current">
            { name }
          </span>
        </div>

        <div className="bag-container">
          <div className="bag-gallery">
            {GalleryImages.nodes.length > 0 ? (
              <BagSlider
                images={ GalleryImages.nodes }
                thumbnails={ GalleryThumbnails.nodes}
              />
            ) : (
              <React.Fragment>
                {image ? (
                  <img
                    src={ `/images/bags/${image}@2x.jpg` }
                    alt={ name }
                  />
                ) : (
                  <div className="bag-gallery--noimage">
                    Photo coming soon.
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="bag-info">
            <div className="bag-info--title">
              <h2 className="bag-info--name">
                { name }
              </h2>

              <img
                src="/images/made-in-usa.png"
                srcSet="/images/made-in-usa.png 1x, /images/made-in-usa@2x.png 2x"
                alt="Made in USA Icon"
              />
            </div>

            <BagColors
              colorsList={ Colors.nodes }
              onColorSelect={ this.onColorSelect }
              activeColor={ activeColor }
            />

            <div className="bag-info--row">
              <span className="bag-info--param">
                Ink Color:
              </span>
              <span className="bag-info--value">
                <img
                  src="/images/pms.png"
                  srcSet="/images/pms.png 1x, /images/pms@2x.png 2x"
                  alt="Color Palette Circle"
                  style={{
                    marginRight: '15px'
                  }}
                />
                PMS Match
              </span>
            </div>
            <div className="bag-info--row">
              <span className="bag-info--param">
                Plate:
              </span>
              <span
                className="bag-info--value bag-info--value__action"
                onClick={ () => this.showPopup('plateQuote') }
              >
                Plate Quote
              </span>
            </div>
            <div className="bag-info--row">
              <span className="bag-info--param">
                Lead time:
              </span>
              <span className="bag-info--value">
                { leadtime }
              </span>
            </div>
            <div className="bag-info--row">
              <span className="bag-info--param">
                Thickness:
              </span>
              <span className="bag-info--value">
                { thickness }
              </span>
            </div>
            <div className="bag-info--description">
              { description }
            </div>
            <div className="bag-info--note">
              This bag can be custom made in many sizes and thicknesses
            </div>
            <div className="bag-info--buttons">
              <div
                className="bag-info--button"
                onClick={ () => this.showPopup('quote') }
              >
                Quote
              </div>
              <div
                className="bag-info--button"
                onClick={ () => this.showPopup('sample') }
              >
                Sample
              </div>
            </div>
          </div>

          {false && Pricelist.nodes[0] && (
            <PriceTable data={ Pricelist.nodes[0].data } />
          )}
        </div>

        {popup === 'plateQuote' && (
          <Popup
            title="Plate Quote"
            onClose={ this.onClosePopup }
          >
            <PlateQuoteForm />
          </Popup>
        )}

        {popup === 'quote' && (
          <Popup
            title="Quote"
            onClose={ this.onClosePopup }
          >
            <QuoteForm
              colors={ colorsList }
              bags={ bagsList }
              defaultColor={ defaultColor }
              defaultBag={ defaultBag }
            />
          </Popup>
        )}

        {popup === 'sample' && (
          <Popup
            title="Sample"
            onClose={ this.onClosePopup }
          >
            <SampleRequestForm
              colors={ colorsList }
              bags={ bagsList }
              defaultColor={ defaultColor }
              defaultBag={ defaultBag }
            />
          </Popup>
        )}
      </Layout>
    )
  }
}

BagTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export const query = graphql`
  query($slug: String!, $gallery: String!) {
    Bag: bagsYaml(slug: { eq: $slug }) {
      name
      image
      leadtime
      thickness
      description
    }

    GalleryImages: allFile(filter: {
      absolutePath:{
        regex: $gallery
      }
    }) {
      nodes {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    GalleryThumbnails: allFile(filter: {
      absolutePath:{
        regex: $gallery
      }
    }) {
      nodes {
        childImageSharp {
          fixed(width: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    Pricelist: allPricelistYaml(
      filter: {
        bag: {
          eq: $slug
        }
      }
    ) {
      nodes {
        data {
          _3000
          _5000
          _10000
          number
          size
          pack
          weight
          template
        }
      }
    }

    Bags: allBagsYaml {
      nodes {
        name
      }
    }

    Colors: allColorsYaml {
      nodes {
        name
        code
      }
    }
  }
`

/*
export const query = graphql`
  query ($slug: String!, $custom: Boolean!) {
    Bag: bagsYaml(slug: {eq: $slug}) {
      name
      image
      description
      leadTime
      thickness
      colors@include(if: $custom_colors) {
        name
        code
      }
    }
  }
`
*/

export default BagTemplate