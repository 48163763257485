import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const theme = (theme) => ({
  ...theme,
  borderRadius: 8,
  colors: {
  ...theme.colors,
    primary: '#453C5B',
    primary25: '#F48E27',
    primary50: '#F48E27',
    primary75: '#F48E27',
    neutral20: '#DFDFDF',
    neutral30: '#DFDFDF'
  },
})

const QuoteForm = ({ colors, bags, thickness, defaultColor, defaultBag }) => (
  <form
    className="contact-form"
    name="quote-form"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    method="POST"
    action="#"
  >
    <div className="contact-form--field">
      <label className="contact-form--label">
        Name
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="name"
        placeholder="Enter your name"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Company
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="company"
        placeholder="Enter your company name"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Phone Number
      </label>

      <input
        className="contact-form--input"
        type="tel"
        name="tel"
        placeholder="Phone Number"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Email*
      </label>

      <input
        className="contact-form--input"
        type="email"
        name="email"
        placeholder="Enter your email"
        required
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Bag type
      </label>

      <Select
        className="contact-form--select"
        classNamePrefix="contact-form--select"
        placeholder="Please select type"
        options={ bags }
        theme={ theme }
        isClearable={ true }
        defaultValue={ defaultBag }
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Bag Size
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="size"
        placeholder="Width x Height x Gusset"
      />
    </div>

    <div className="contact-form--field contact-form--field__fullWidth">
      <label className="contact-form--label">
        Ink Color (Separated by comma)
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="ink"
        placeholder="Ink Color"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label contact-form--label__small">
        Film Color
      </label>

      <Select
        className="contact-form--select"
        classNamePrefix="contact-form--select"
        placeholder="Please select color"
        options={ colors }
        theme={ theme }
        isClearable={ true }
        defaultValue={ defaultColor }
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label contact-form--label__small">
        Film Thickness
      </label>

      <Select
        className="contact-form--select"
        classNamePrefix="contact-form--select"
        placeholder="Please select thickness"
        options={ thickness }
        defaultValue={ thickness[0] }
        theme={ theme }
        isClearable={ true }
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Imprint Area
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="imprint"
        placeholder="Imprint Area"
      />
    </div>

    <div className="contact-form--field">
      <div className="contact-form--label">
        Print Side
      </div>

      <div className="contact-form--radioGroup">
        <div className="contact-form--radio">
          <input
            id="quoteRadioSideOne"
            type="radio"
            name="side"
            value="1"
          />
          <label htmlFor="quoteRadioSideOne">
            One
          </label>
        </div>

        <div className="contact-form--radio">
          <input
            id="quoteRadioSideTwo"
            type="radio"
            name="side"
            value="2"
            defaultChecked
          />
          <label htmlFor="quoteRadioSideTwo">
            Two
          </label>
        </div>
      </div>
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Quantity (Separated by comma)
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="quantity"
        placeholder="Quantity"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        ASI/Sage/UPIC/PPAI Number
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="number"
        placeholder="Enter your number"
      />
    </div>

    <div className="contact-form--field contact-form--field__fullWidth">
      <label className="contact-form--label">
        Message
      </label>

      <textarea
        className="contact-form--input"
        name="message"
        placeholder="Enter your message"
      />
    </div>

    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="quote-form" />

    <button
      className="contact-form--button"
      type="submit"
    >
      Send
    </button>
  </form>
)

QuoteForm.defaultProps = {
  thickness: [
    {
      value: 1.75,
      label: 1.75
    },
    {
      value: 1.25,
      label: 1.25
    },
    {
      value: 2.5,
      label: 2.5
    }
  ]
}

QuoteForm.propTypes = {
  colors: PropTypes.array.isRequired,
  bags: PropTypes.array.isRequired,
  thickness: PropTypes.array.isRequired,
  defaultColor: PropTypes.object,
  defaultBag: PropTypes.object
}

export default QuoteForm