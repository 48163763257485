import React from 'react'
import PropTypes from 'prop-types'

const Popup = ({ onClose, title, children }) => (
  <div className="popup-wrapper" onClick={ onClose }>
    <div className="popup">
      <div className="popup-heading">
        <h2 className="page-heading">
          { title }
        </h2>
      </div>

      <div className="popup-content">
        { children }
      </div>

      <div
        className="close-button popup-close"
        onClick={ onClose }
      />
    </div>
  </div>
)

Popup.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Popup