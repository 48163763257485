import React from 'react'
import PropTypes from 'prop-types'

const BagColors = ({ colorsList, onColorSelect, activeColor }) => (
  <div className="bag-info--palette">
    <div className="bag-info--row">
      <span className="bag-info--param">
        Film Color:
      </span>
      <span className="bag-info--value">
        { activeColor ? activeColor : '–' }
      </span>
    </div>

    <div className="bag-info--colors">
      {colorsList.map(color => (
        <div
          className={ `bag-info--color ${activeColor === color.name ? 'active' : ''}` }
          title={ color.name }
          key={ color.name }
          style={{
            backgroundColor: color.code
          }}
          onClick={ () => onColorSelect(color.name) }
        />
      ))}
    </div>

    <div className="bag-info--warn">
      Not all film colors are available in all sizes and thicknesses
    </div>
  </div>
)

BagColors.defaultProps = {
  activeColor: null
}

BagColors.propTypes = {
  colorsList: PropTypes.array.isRequired,
  onColorSelect: PropTypes.func.isRequired,
  activeColor: PropTypes.string
}

export default BagColors