import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import SVG from 'react-inlinesvg'
import Img from 'gatsby-image'

class BagSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: null,
      thumbnails: null
    }

    this.sliderPrev = this.sliderPrev.bind(this)
    this.sliderNext = this.sliderNext.bind(this)
  }

  sliderPrev() {
    this.imagesSlider.slickPrev();
    this.thumbsSlider.slickPrev();
  }

  sliderNext() {
    this.imagesSlider.slickNext();
    this.thumbsSlider.slickNext();
  }

  componentDidMount() {
    this.setState({
      images: this.imagesSlider,
      thumbnails: this.thumbsSlider
    })
  }

  render() {
    const { images, thumbnails } = this.props
    return (
      <div className="bag-slider">
          <Slider
            className="bag-slider--images"
            arrows={ false }
            speed={ 500 }
            asNavFor={ this.state.thumbnails }
            ref={ node => (this.imagesSlider = node) }
          >
            {images.map((image, index) => (
              <Img fixed={ image.childImageSharp.fixed } key={ index } />
            ))}
          </Slider>

          <div className="bag-slider--bottom">
            <div className="bag-slider--nav prev" onClick={ this.sliderPrev }>
              <SVG src="/images/arrow_left.svg" />
            </div>

            <Slider
              className="bag-slider--thumbnails"
              arrows={ false }
              asNavFor={ this.state.images }
              slidesToShow={ 3 }
              slidesToScroll={ 1 }
              focusOnSelect={ true }
              centerPadding={ 0 }
              variableWidth={ true }
              ref={ node => (this.thumbsSlider = node) }
            >
              {thumbnails.map((thumbnail, index) => (
                <div className="bag-slider--thumbnail" key={ index }>
                  <Img fixed={ thumbnail.childImageSharp.fixed } />
                </div>
              ))}
            </Slider>

            <div className="bag-slider--nav next" onClick={ this.sliderNext }>
              <SVG src="/images/arrow_right.svg" />
            </div>
          </div>
      </div>
    )
  }
}

BagSlider.propTypes = {
  images: PropTypes.array.isRequired,
  thumbnails: PropTypes.array.isRequired
}

export default BagSlider